import React from "react"
import "./index.css"
import { Container, Row, Col, Modal, Button, Spinner } from "react-bootstrap"
import { Link } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import FirebaseApp from "./../config/firebase"
import Logo from "./../images/colorlogo-01.png"
import { navigate } from "gatsby" //import navigate from gatsby
let database = FirebaseApp.database().ref("/")
// STUDENT OR TEACHET LOGN FUNCTION

export default class LoginForm extends React.Component {
  constructor() {
    super()
    this.state = {
      show: false,
      email: "",
      confirmemail: "",
      password: "",
      err: "",
      confirmerr: "",
      sent: "",
      isLoader: false,
      message: "",
      uid:"gYZGQ4rWNbckHMMDPYV4tZlUH3b2"
    }
  }
  componentDidMount = async() => {

    // FirebaseApp.auth().onAuthStateChanged(user => {
    //   if (user && user.emailVerified) {
    //     database
    //       .child("Registration/" + user.uid + "/")
    //       .once("value", async res => {
    //         let data = res.val()
    //         if (data.isCompleted) {
    //           if (data.isCompleted === "inProgress") {
    //             navigate("/courses") //navig ate to edit page
    //           } else if (data.isCompleted === "completed") {
    //             navigate("/applyfornewcourse") //navig ate to edit page
    //           }
    //         } else {
    //           navigate("/courses")
    //         }
    //       })
    //   }
    // })
  }

  handleClose = () => {
    this.setState({ show: false })
  }
  handleShow = () => {
    this.setState({ show: true })
  }

  Login = async () => {
    let { email, password, message } = this.state
    if (email === "") {
      this.setState({ err: "Please Enter your valid Email" })
    } else if (password === "") {
      this.setState({ err: "Please Enter your Password" })
    } else {
      this.setState({ isLoader: true })
      await FirebaseApp.auth()
        .signInWithEmailAndPassword(email, password)
        .then(async v => {
          if (v.user.emailVerified) {
            await database
              .child("Registration/" + v.user.uid + "/")
              .once("value", async res => {
                let data = res.val()
                data.uid = v.user.uid
                console.log(data)
                this.setState({ isLoader: true })
                if (data.isCompleted) {
                  if (data.isCompleted === "inProgress") {
                    navigate("/courses") //navig ate to edit page
                  } else if (data.isCompleted === "completed") {
                    navigate("/applyfornewcourse") //navig ate to edit page
                  }
                } else {
                  navigate("/courses")
                }
                if (data.approved) {
                  this.setState({ err: "" })
                  this.setState({ email: "" })
                  this.setState({ password: "" })
                  this.setState({ confirmemail: "" })
                  localStorage.setItem("user", JSON.stringify(data))
                }
              })
          } else {
            navigate("/emailVerification") //navigate to edit page
          }
        })
        .catch(error => {
          this.setState({ err: error.message, isLoader: false })
        })
    }
  }

  forgetPassword = () => {
    var auth = FirebaseApp.auth()
    auth
      .sendPasswordResetEmail(this.state.confirmemail)
      .then(res => {
        this.setState({
          set:
            "Your reset password request send successfully on your email account, please check your email.",
        })
      })
      .catch(err => {
        if (
          err.message ===
          "There is no user record corresponding to this identifier. The user may have been deleted."
        ) {
          this.setState({
            confirmerr: "There is no user record on this email!",
          })
        } else {
          this.setState({ confirmerr: err.message })
        }
      })
  }

  render() {
    let {
      show,
      email,
      confirmemail,
      password,
      err,
      confirmerr,
      sent,
      isLoader,
      message,
    } = this.state
    return (
      <div
        style={{
          backgroundColor: "#fae6ff",
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Modal
          show={show}
          onHide={() => this.handleClose()}
          backdrop="static"
          keyboard={false}
        >
          {/* FORGET PASSWORD */}
          {sent === "" ? (
            <>
              <Modal.Header closeButton className="_modalHeader">
                <Modal.Title className="_ModalTitle">
                  Enter your Email:
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="_modalBody">
                <div className="_modalinputDiv">
                  <input
                    type="email"
                    className="_modalInput"
                    placeholder="Enter Email Address"
                    onChange={e => {
                      this.setState({ confirmemail: e.target.value })
                      this.setState({ err: "" })
                    }}
                  />
                </div>
                <div className="_err">{this.state.confirmerr}</div>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  className="_modalBtn"
                  onClick={() => this.forgetPassword()}
                >
                  Send Email
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <div className="reset_pass">
              <p className="rseet_mesage">{sent}</p>{" "}
              <button
                onClick={() => this.setState({ show: false })}
                className="ok_btn"
              >
                OK
              </button>{" "}
            </div>
          )}
        </Modal>
        <Container>
          <Row className="justify-content-center">
            <Col lg="6">
              <div className="login">
                <p className="_formTitle">Student Login</p>
                <img
                  src={Logo}
                  className="logo"
                />
                <div className="_inputDiv">
                  <input
                    type="email"
                    className="_input"
                    name="email"
                    placeholder="Enter Email Address"
                    onChange={e => {
                      this.setState({ email: e.target.value })
                      this.setState({ err: "" })
                    }}
                  />
                </div>

                <div className="_inputDiv">
                  <input
                    type="password"
                    className="_input"
                    name="password"
                    placeholder="Enter Password"
                    onChange={e => {
                      this.setState({ password: e.target.value })
                      this.setState({ err: "" })
                    }}
                  />
                </div>
                <div className="_err">{err}</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    justifyContent: "space-around",
                  }}
                >
                  <div className="_text">
                    {" "}
                    Not a member? <Link to="signup">Signup</Link>
                  </div>
                  <div className="_text" onClick={() => this.handleShow()}>
                    Forgot password
                  </div>
                </div>

                <button className="_btn" onClick={() => this.Login()}>
                  {isLoader ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
